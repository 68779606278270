@use 'sass:math';

$footer-horizontal-padding: 24px;

footer.page-footer {
    /**
     * General styling
     */
    background-color: $cblys__lightgray2;
    color: $cblys__black;

    .footer.content > * a {
        color: $cblys__black;
        font-weight: inherit;

        &:before {
            display: none;
        }
    }

    .footer.content {
        display: flex;
        flex-direction: column-reverse;
        padding: inherit;

        > * .block-static-block {
            display: block;
        }
    }

    .cblysfooter {
        font-size: math.div(16rem, 14);
        padding: inherit;
        width: 100%;

        .block-static-block {
            margin-bottom: 0;
        }
    }

    .widget.block.block-static-block {
        margin: 0;
    }

    .footer-content {
        margin: auto;
        max-width: $layout__max-width + 2 * $footer-horizontal-padding;
        padding: 0 $footer-horizontal-padding;
    }

    /**
     * About us section
     */
    .footer-about-us {
        background-color: $cblys__lightgray2;
        padding: 80px 0;
        text-align: center;

        h3 {
            font-size: math.div(32rem, 14);
            font-weight: normal;
            margin: 0 0 40px 0;
        }

        .about-us-description {
            margin: 40px 0;

            > div {
                padding: 0 30px;
                font-size: math.div(18rem, 14);

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        .footer-learn-more {
            @include cblys-transparent-button;

            display: inline-block;
            padding: 16px 20px;
        }
    }


    /**
     * Primary section
     */
    .footer-primary {
        background-color: $cblys__beige;
        padding: 80px 0;

        .footer-content > * {
            padding: 0;
        }

        h6 {
            font-family: inherit;
            font-size: 16px;
            margin: 0 0 16px 0;
            text-transform: uppercase;

            .trigger {
                display: none;
            }
        }

        .footer__telephone {
            margin: 16px 0;

            .phone-number-label {
                padding-right: 6px;
            }

            .phone-number {
                font-family: $font-family__header;
                font-size: 2.86rem;
                font-weight: bold;
            }
        }

        .footer-nearset-store {
            @include cblys-transparent-button;

            display: inline-block;
            margin: 26px 0;
            padding: 16px 20px;
        }

        .opening-hours {
            display: flex;
            flex-direction: column;
            list-style-type: none;

            .hours-header {
                font-weight: bold;
                padding-bottom: 16px;
            }

            li {
                font-size: inherit;
            }
        }

        .some-icons {
            display: flex;
            flex-direction: column;

            a{
                display: flex;
                margin-right: auto;
                padding-top: 2.5rem;

                &:first-child {
                    padding-top: 1rem;
                }
            }
        }

        .footer__links {
            display: flex;
            justify-content: space-between;
        }

        .footer__li {
            font-size: inherit;
            padding-bottom: 16px;

            &:last-child {
                padding-bottom: 0;
            }
        }

        .categories {
            margin-left: auto;
        }
    }

    /**
     * Secondary section
     */
    .footer-secondary {
        background-color: $cblys__darkbrown;
        padding: 32px 0;

        .footer-content > div {
            align-items: center;
            display: flex;
            padding: 0;
        }


        .service-review {
            .lipscore-service-review-badge-small-short {
                margin-left: -16px;

                * {
                    fill: $cblys__lightgray2 !important;
                }
            }

            .lipscore-service-review-logo {
                display: block !important;
            }
        }

        .payment-icons {
            justify-content: space-between;
        }

        .ecommerce-icons {
            justify-content: right;

            a:not(:first-of-type) {
                padding-left: 32px;
            }
        }
    }

    /**
     * Tertiary section
     */
    .footer-tertiary {
        background-color: $cblys__black;
        color: $cblys__lightgray2;
        font-size: math.div(12rem, 14);

        .footer-content {
            display: flex;
            justify-content: center;
            padding: 13.5px $footer-horizontal-padding;

            > * {
                padding: 0 12px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        .privacy {
            a {
                color: inherit;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .copyright {
            background-color: inherit;
            border: none;
            color: inherit;
            display: inline;
        }
    }
}

@include max-screen($screen__l + 1) {
    footer.page-footer {
        /**
         * Secondary section
        */
        .footer-secondary {
            padding: 24px 0;

            .footer-content > div {
                justify-content: center;
                margin: 24px 0;
            }

            div.payment-icons {
                justify-content: space-around;
            }
        }
    }
}

@include max-screen($screen__m + 1) {
    footer.page-footer {
        /**
         * About us section
         */
        .footer-about-us {
            padding: 72px 0;

            h3 {
                font-size: math.div(28rem, 14);
            }

            .about-us-description {
                margin: 0;

                > div {
                    padding: 0;
                    margin-bottom: 48px;
                }
            }

            .footer-learn-more {
                margin-top: -8px;
            }
        }

        /**
         * Primary section
         */
        .footer-primary {
            padding: 48px 0;

            .closed-on-mobile {
                h6 + div {
                    display: none;
                }

                h6 .trigger {
                    &:before {
                        @include material-icon($icon-arrow-down-thin);
                    }
                }
            }

            h6 {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin: 0;
                padding: 24px 0;

                .trigger {
                    display: flex;

                    &:before {
                        @include material-icon($icon-arrow-up-thin);

                        font-size: 1.715rem;
                    }
                }

                + div {
                    margin: -8px 0 24px 0;
                }
            }

            .footer-content >  * {
                border-top: 1px solid $cblys__re-lighter-brown;

                &:last-child {
                    border-bottom: 1px solid $cblys__re-lighter-brown;
                }
            }

            .footer__telephone {
                margin: 0;
            }

            .social {
                border: none;
                margin-top: 48px;
                order: 1;

                h6 {
                    display: none;
                }

                .footer__so-me {
                    margin: 0;
                }

                .some-icons {
                    flex-direction: row;
                    justify-content: space-around;
                }

                a {
                    margin: 0;
                    padding: 0;

                    &:first-child {
                        padding: 0;
                    }
                }
            }

            .categories {
                display: block;
            }
        }
    }
}

@include max-screen($screen__s + 1) {
    footer.page-footer {
        /**
        * Tertiary section
        */
        .footer-tertiary {
            .footer-content {
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 32px $footer-horizontal-padding;

                > * {
                    padding: 0;
                }
            }

            .copyright {
                padding: 0;
            }

            .privacy {
                margin-top: 24px;
                order: 1;
                padding: 0;
                text-align: center;
                width: 100%;
            }
        }
    }
}
