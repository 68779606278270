@use 'sass:math';

//
//  Common
//  _____________________________________________

.checkout {
    &-cart-index,
    &-klarna-index {
        header .header.content {
            flex-direction: column-reverse;
            gap: 16px;
            padding-bottom: 0;

            .checkout__back-link-wrapper {
                width: 100%;
                height: auto;
                padding: 0;
                justify-content: flex-start;
                @include lib-icon-font(
                    $_icon-font-content: $icon-arrow-left-thin,
                    $_icon-font-size: 16px,
                    $_icon-font-line-height: 16px
                );
                display: flex;

                &::before {
                    margin-right: 8px;
                    transform: rotate(180deg);
                }
            }

            .checkout__back-link {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.minicart-wrapper .item, .cart.table-wrapper .item, .order.table-wrapper .item, .order-details .order-summary{
    .old-price {
        display: block;

        .price {
            padding: 0;
            text-decoration: line-through;
        }

        & + .price {
            color: $cblys__red;
        }
    }
}

.minicart-wrapper, .cart.table-wrapper, .order.table-wrapper {
    .advertised-price-wrapper {
        display: flex;
        align-items: baseline;
        color: $cblys__re-light-brown;
        letter-spacing: 0.04em;
        font-size: 1rem;
        margin-top: 0.5rem;

        .advertised-price-label {
            margin-right: 0.35rem;
        }

        .old-price .price {
            text-decoration: none;
        }
    }
}

.minicart-wrapper, .cart-summary {
    .block {
        .title > strong {
            display: block;
            padding: 0;
            cursor: pointer;
            margin: 0;

            &:hover {
                cursor: pointer;
                opacity: .9;
            }

            @include lib-heading(h3);
            @include lib-icon-font(
                    $icon-down,
                $_icon-font-size: 26px,
                $_icon-font-position: after
            );
        }

        &.active {
            .title > strong {
                @include lib-icon-font(
                        $icon-up,
                    $_icon-font-size: 26px,
                    $_icon-font-position: after
                );
            }
        }

        &.discount {
            margin-bottom: 10px;

            .title {
                display: flex;

                > strong {
                    margin-top: 1.5rem;
                    text-transform: uppercase;
                    letter-spacing: 0.04em;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1.25rem;
                    padding-left: 0.7rem;

                    &:after {
                        margin: 0;
                        padding-left: 0.5rem;
                        font-size: 1.2rem;
                    }
                }
            }

            .fieldset.coupon {
                margin: 0;
                padding: 0.5rem 0.5rem 0;

                .field {
                    margin: 0;
                }

                .actions-toolbar {
                    margin: 0 auto;
                    padding: .5rem 0;
                }
            }

            .action.primary {
                @include cblys-re-outline-brown-btn;
                margin: 0 auto;
            }
        }
    }

    /* Klarna On-Site Messaging */
    klarna-placement *::part(osm-container), klarna-placement::part(osm-container) {
        border-color: #eae7e4;
        border-radius: 9px;
        font-family: $font-family__base;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        margin: 0 10px 10px 10px;
        max-width: none;
        min-height: 70px;
        padding: 9px 14px;
    }
}

.log-in-reminder {
    margin: 20px 0;
    padding-left: 0.7rem;

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.minicart-wrapper .amount.price-container, .cart-totals .table-wrapper .table.totals {
    margin: 0;

    tr.totals,
    tr.totals-tax {
        th.mark,
        td.amount {
            padding: 3px 10px;
            font-weight: 400;
        }
    }

    tr.totals {
        font-size: math.div(16rem, 14);
    }

    th strong {
        font-weight: 500;
    }

    .price {
        float: right;
        font-size: math.div(16rem, 14);
    }

    .shipping_from {
        td {
            color: $cblys__darkbrown;
            text-align: right;
            padding: 0.25rem 0.8rem;
            letter-spacing: 0.04em;
        }
    }

    .grand.totals .mark {
        padding-right: 0;
        font-size: math.div(16rem, 14);
    }

    tr.totals-tax {
        color: $cblys__re-light-brown;
        font-size: 1rem;

        th.mark {
            padding-left: 20px;
        }
    }
}
