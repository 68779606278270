@use 'sass:math';

@mixin cblys-price-style1($cblys__price-font-size) {
    @include cblys-base-title($cblys__price-font-size, $font-family__base);
    text-align: right;
    letter-spacing: 0.04em;
    font-weight: normal;
    display: inline;
}

@mixin cblys-price-style2($cblys__price-font-size) {
    @include cblys-base-title($cblys__price-font-size, $font-family__base);
    letter-spacing: 0.04em;
    font-weight: 400;
    display: inline;
}

.price-box .price, .price {
    @include cblys-price-style2(1.15rem);
}

.currency-symbol {
    padding-left: 0.3rem;
    text-transform: uppercase;
}

.special-price {
    color: $cblys__black;
}

.old-price,
.old.price {
    font-size: 1rem;
}

.product-info-main .price-box .price {
    @include cblys-price-style2(2rem);
}

.product-info-main .custom-price-box.with-advertised-price .old-price .price {
    @include cblys-price-style2(1.15rem);
}

.cart.items.data.table,
.minicart-wrapper,
.order.table-wrapper,
.order-details .order-summary {
    .old-price .price {
        @include cblys-price-style2(math.div(12rem, 14));
    }
}

.cart-summary .table-wrapper .table.totals .totals-tax .price {
    @include cblys-price-style2(1rem);
}

.sly-old-price {
    order: 1;

    &:not(.no-display) + .normal-price {
        color: $cblys__red;
    }
}

// Desktop
@include min-screen($screen__m) {
    .product-info-main .product-info-price {
        .special-price .price,
        .custom-price-box.with-discount .old-price .price {
            @include cblys-price-style2(2.425rem);
        }
    }
}
