@use 'sass:math';

.checkout-cart-index {
    header .header.content {
        .logo {
            position: relative;
            left: 0;
            transform: none;
            margin: 0;
        }

        > .nav-toggle {
            display: none;
        }
    }

    .page-title {
        @include cblys-title(34px);
        letter-spacing: 0.04em;
    }

    .form-cart .action.update {
        display: none;
    }
}

.summary {
    @extend .abs-adjustment-incl-excl-tax;

    &.title {
        font-size: 1rem;
        display: flex;
        white-space: nowrap;
        margin: 0;
        font-weight: 500;
        padding-left: 0.7rem;

        &:after {
            content: '';
            border-bottom: $border-width__base solid $cblys__lightgray3;
            width: 100%;
            height: 10px;
            margin-left: $indent__s;
        }
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            display: flex;
            flex-direction: column;
            padding: 0.5rem;

            .field {
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }
            }

            .methods {
                .field {
                    & > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            & > .legend,
            & > .legend + br {
                @extend .abs-no-display;
            }
        }
    }

    .actions-toolbar {
        & > .primary {
            button {
                @extend .abs-revert-secondary-color;
            }
        }
    }
}

//  Totals block
.cart-totals {
    padding-top: 24px;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }
}

//  Products table
.cart.table-wrapper,
.order.table-wrapper {
    .cart-mini-title {
        font-size: math.div(16rem, 14);
        display: flex;
        white-space: nowrap;
        margin-top: 16px;
        font-weight: 400;

        &:after {
            content: '';
            border-bottom: $border-width__base solid $cblys__lightgray3;
            width: 100%;
            height: 13px;
            margin-left: $indent__s;
        }
    }

    .items {
        thead {
            display: none;
        }

        & > .item {
            border-bottom: $border-width__base solid $cblys__lightgray3;
            position: relative;
        }
    }

    .cart.item,
    .order.item {
        display: block;
        padding: 16px 0;

        &-actions td {
            padding-bottom: $indent__s;
            text-align: center;
            white-space: normal;
        }

        .actions-toolbar,
        .action.action-delete {
            display: none;
        }

        .item-info {
            display: grid;
            grid-template-areas: "image details quantity subtotal";
            grid-template-columns: min-content 1fr 180px 120px;
        }

        .crosssell-info {
            display: block;
            width: 100%;
        }

        .col {
            align-items: center;
            display: flex;
            padding: 0;
        }

        .col.image {
            align-items: start;
            grid-area: image;
            padding-right: 24px;
        }

        .col.item {
            grid-area: details;
            position: relative;

            .product-image-wrapper {
                background: inherit;
            }
        }

        .col.price {
            display: none;
        }

        .col.qty {
            align-items: end;
            flex-direction: column;
            grid-area: quantity;
            justify-content: center;
            padding-right: 24px;

            .label {
                @extend .abs-visually-hidden;
            }

            .polarcart-qty-wrapper {
                justify-content: flex-start;
                padding-left: 0;
            }

            .energy-label {
                margin-bottom: 24px;

                a {
                    order: -1;
                }
            }
        }

        .col.subtotal {
            align-items: end;
            flex-direction: column;
            grid-area: subtotal;
            justify-content: center;

            :not(.old-price) > .price {
                font-size: 1rem;
                font-weight: bold;
            }
        }

        &-info td.col.price {
            display: none;
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .product {
        &-item-photo {
            display: flex;
            left: 0;
            max-width: 80px;
            padding: 0;
            width: 100%;
        }

        &-item-details {
            white-space: normal;

        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;

        dt {
            font-weight: 400;
        }
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        text-align: center;

        .action.primary {
            @include cblys-re-green-btn;
            margin: 0 auto;
            padding: 14px 2rem;
            width: calc(100% - 20px);
        }
    }

    .cart-summary {
        background: $cblys__white;
        box-shadow: 0 4px 4px rgba(0,0,0,.25);
        padding: 24px 14px;
    }

    .block.shipping {
        display: none;
    }
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    & + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        text-align: right;
        vertical-align: middle;
        padding: 1rem;
    }
}


//
// Tablet
// _____________________________________________
@include screen($screen__m, $screen__l) {
    .cart-container {
        display: flex;
        flex-direction: column-reverse;

        .cart-summary {
            width: 100%;
        }

        .form.form-cart {
            width: 100%;
            padding: 0;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .checkout-cart-index .page-main > .page-title-wrapper {
        padding-left: 24px;

        .page-title {
            margin-right: auto;
            margin-left: auto;
            font-size: 2rem;
        }
    }

    .page-main {
        padding: 0;
    }

    .cart,
    .order {
        &-container {
            display: flex;
            flex-direction: column;

            .form-cart {
                order: 0;
                padding-right: 0;
            }

            .cart-summary {
                order: 1;
                box-shadow: none;
                border: none;
                margin: 0 -1rem;

                .summary.title {
                    display: none;
                }

                .cart-totals {
                    padding: 0;
                }
            }
        }

        &.table-wrapper {
            overflow: inherit;
            padding: 0 10px;
            margin-bottom: 0;

            .cart-mini-title {
                display: none;
            }

            thead {
                .col {
                    &:not(.item) {
                        display: none;
                    }
                }
            }

            .col.msrp {
                white-space: normal;
            }

            .items {
                border-top: $border-width__base solid $cblys__lightgray3;
                margin-bottom: 0;
            }

            .cart.item,
            .order.item {
                display: block;
                padding: 24px 0;

                .item-info {
                    grid-template-areas:
                        "image details details"
                        "image quantity subtotal";
                    grid-template-columns: min-content auto auto;
                }

                .crosssell-info {
                    margin-top: 16px;
                }

                &:last-of-type {
                    border: none;
                }

                .col.image {
                    padding-right: 12px;

                    .product-item-photo {
                        width: 80px;
                    }
                }

                .col.item {
                    display: flex;
                    align-items: flex-start;
                }

                .col.qty {
                    align-items: start;
                    justify-content: end;
                    padding-right: 0;

                    .energy-label {
                        justify-content: flex-end;
                        margin: 12px 0 0 0;

                        a {
                            order: 0;
                        }
                    }

                    .field.qty {
                        margin-top: 12px;
                    }
                }

                .col.subtotal {
                    justify-content: end;
                    padding-bottom: 4px;
                }

                .action-delete {
                    font-size: 13px;
                    padding: 10px 20px;
                }

                &-info {
                    display: block;
                    width: 100%;
                    position: relative;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    td {
                        vertical-align: middle;

                        div {
                            align-items: center;
                            flex-wrap: wrap;

                            &.advertised-price-wrapper {
                                margin-bottom: 2.5rem;
                            }

                            &.polarcart-qty-wrapper {
                                padding-left: 0.35rem;
                            }
                        }
                    }
                }
            }
        }
    }

    #gift-options-cart {
        order: 3;
    }

    .cart-container {
        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
        }
    }

    .cart.table-wrapper .col.qty .polarcart-qty-wrapper span {
        height: 25px;
        width: 25px;
        font-size: 1rem;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .cart-container {
        @extend .abs-add-clearfix-desktop;
        .form-cart {
            width: 65%;
            float: left;
            padding-right: 4%;
        }

        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 33%;

        .actions-toolbar {
            .column.main & {
                @extend .abs-reset-left-margin-desktop;
                & > .secondary {
                    float: none;
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {
                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                }
            }

            .col.qty .polarcart-qty-wrapper {
                justify-content: flex-end;
            }

            .crosssell {
                margin: 0 130px;
            }
        }
    }
}

@include max-screen($screen__xxs) {
    .cart.table-wrapper,
    .order.table-wrapper {
        .cart.item {
            .item-info {
                grid-template-areas:
                    "image details details"
                    "image quantity quantity"
                    "image subtotal subtotal";
            }

            .col.subtotal {
                flex-direction: row;
                justify-content: start;
                margin-top: 12px;

                .old-price {
                    margin-right: 12px;
                }
            }
        }
    }
}
